// PopularDestinations.js
import React, { useState } from 'react';
import './PopularDestination.css';
import lasVegasImage from '../../Assets/losvegas.png';
import bostonImage from '../../Assets/boston.png';
import columbusImage from '../../Assets/columbus.png';
import newYorkImage from '../../Assets/newyork.png';
import honoluluImage from '../../Assets/honolulu.png';
import Loader from '../../Pages/Loader';

const PopularDestination = ({handleSearch , loading}) => {

  // const [loading, setLoading] = useState(false);

    const destinations = [
        {
          name: 'Las Vegas',
          image: lasVegasImage,
        },
        {
          name: 'Boston',
          image: bostonImage,
        },
        {
          name: 'Columbus',
          image: columbusImage,
        },
        {
          name: 'New York',
          image: newYorkImage,
        },
        {
          name: 'Honolulu',
          image: honoluluImage,
        },
      ];
    
      return (
        <>
        {loading ?  ( <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
          }}
        >
          <Loader />
        </div>) :(
          <div className="popular-destinations-container">
            <div className="popular-destinations">
          <h2>Popular <span style={{color:"#00a58e"}}>Destinations</span></h2>
          <div className="destinations-grid">
            <div className="destination-card cardd">
              <img src={destinations[0].image} alt={destinations[0].name} onClick={() => handleSearch("GLA", "LAS")}/>
              <div className="destination-name">{destinations[0].name}</div>
            </div>
            <div className="destination-card">
              <img src={destinations[1].image} alt={destinations[1].name} onClick={() => handleSearch("GLA", "BOS")}/>
              <div className="destination-name">{destinations[1].name}</div>
            </div>
            <div className="destination-card">
              <img src={destinations[2].image} alt={destinations[2].name} onClick={() => handleSearch("GLA", "CMH")}/>
              <div className="destination-name">{destinations[2].name}</div>
            </div>
            <div className="destination-card">
              <img src={destinations[3].image} alt={destinations[3].name} onClick={() => handleSearch("GLA", "NYC")}/>
              <div className="destination-name">{destinations[3].name}</div>
            </div>
            <div className="destination-card">
              <img src={destinations[4].image} alt={destinations[4].name} onClick={() => handleSearch("GLA", "HNL")}/>
              <div className="destination-name">{destinations[4].name}</div>
            </div>
          </div>
        </div>
          </div>
        )}
        </>
      );
    };

export default PopularDestination;