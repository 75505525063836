import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SearchedFligh from "./Pages/SearchedFlightPage/SearchedFligh";
import Footer from "./Components/FooterSection/Footer";
import Home from "./Pages/HomePage/Home";
import Mytrip from "./Pages/My-Trip/Mytrip";
import { useEffect } from "react";
import { loadUser } from "./Action/userAction";
import store from './store';
import CreateProduct from "./Components/Admin/CreateProduct";
import { useSelector } from "react-redux";
import Profile from "./Pages/Profile/Profile";
import UpdateProfile from "./Pages/UpdateProfile/UpdateProfile";
import UpdatePassword from "./Pages/UpdatePassword/UpdatePassword";
import List from "./Pages/List/List";
import FlightBooking from "./Pages/FlightBooking/FlightBooking";
import BookingReview from "./Pages/BookingReview/BookingReview";
import SignUp from "./Pages/SignUp/SignUp";
import SignIn from "./Pages/SignIn/SignIn";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/AboutUs/About";
import CallBack from "./Pages/CallBack/CallBack";
import Privacy from "./Pages/Privacy/Privacy";
import Term from "./Pages/TermsandConditions/Term";
import Cancellation from "./Pages/Cancellation/Cancellation";





function App() {

  const { isAuthenticated } = useSelector((state) => state.user);


  useEffect(() => {
    store.dispatch(loadUser());
   
  }, []);


  return (
    <BrowserRouter>
   
      <Routes>
        
        <Route path="/" element={<Home />}></Route>
        <Route path="/flights" element={<List />}></Route>
        <Route path="/contactUs" element={<Contact/>}></Route>
        <Route path="/aboutUs" element={<About />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route path="/terms-and-conditions" element={<Term />}></Route>
        <Route path="/cancellations" element={<Cancellation />}></Route>
        <Route path="/flightDetails/:flightNumber" element={<BookingReview />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/searchedFlight/:id" element={<SearchedFligh />}></Route>
        <Route path="/flightBooking/:id" element={<FlightBooking />}></Route>
        <Route path="/myTrip" element={<Mytrip />}></Route>
        <Route path="/success" element={<CallBack />}></Route>
        <Route path="/account" element={isAuthenticated ? <Profile />:<Navigate to="/" /> }></Route>
        <Route path="/admin/newProduct" element={isAuthenticated ? <CreateProduct /> : <Navigate to="/account" />}  />
        
        <Route path="/me/update" element={isAuthenticated ? <UpdateProfile /> : <Navigate to="/" />} />
        <Route path="/password/update" element={isAuthenticated ? <UpdatePassword /> : <Navigate to="/" />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;