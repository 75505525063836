import React, { useState } from "react";
import "./flightBooking.css";
import { IoMdCheckmark } from "react-icons/io";
import AdultForm from "./AdultForm";
import PaymentDetails from "./PaymentDetails";
import { useLocation, useNavigate } from "react-router-dom";
import "./AdultFrom.css";
import axios from "axios";
import { toast } from 'react-toastify';
import Header from "../../HomeComponents/Header/Header";
import { FaLock } from "react-icons/fa";

const visa = require('../../Assets/Images/Picture certifiedwhite.webp.png')

const FlightBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [passengerFormData, setPassengerFormData] = useState([]);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const { flightData } = location.state;
  // console.log("flight data", flightData);



  const handleAdultFormSubmit = (formData) => {
    setPassengerFormData([...passengerFormData, formData]);
    // console.log(passengerFormData)
  };

  const handlePaymentDetailsSubmit = (formData) => {
    setFlightBookingData((prevData) => ({
      ...prevData,
      paymentDetailsData: formData,
    }));
    // console.log("Payment Form Data:", formData);
  };

  const [flightBookingData, setFlightBookingData] = useState({
    flightNumber: location.state.flightData.flightNumber,
    flightName: location.state.flightData.flightName,
    origin: location.state.flightData.origin,
    destination: location.state.flightData.destination,
    arrivalTime: location.state.flightData.arrivalDateTime,
    destinationTime: location.state.flightData.departureDateTime,
    totalFare: location.state.flightData.totalFare,
    flightClass: location.state.flightData.flightClass,


    // adultFormData: {},
    paymentDetailsData: {},
  });

  const handleBookFlight = async () => {

    if (!isCheckboxChecked) {
      toast.error("Read term&conditions and policies and check the checkbox first.");
      return;
    }
    try {
      const response = await axios.post(
        "/api/v1/flight-booking",
        {
          ...flightBookingData,
          email: email,
          phone: phone,
          adultFormData: passengerFormData,
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);

        // console.log( response.data);
        toast.success("Data saved at id " + response.data.savedBooking._id);
        navigate('/success', { state: { phoneNumber: phone } })

      } else {
        toast.error(response.data.error);
      }


    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.error);
      toast.error("Error while booking Flight:");

    }
  };

  const totalPassenger = flightData.adults + flightData.childrens + flightData.infants;
  return (
   <>
   <Header/>
    <div className="flightBooking-main-container">
      <div className="flightBooking-container">
        <div className="flighBooking-review-container">
          <h3> Review Trip Details and Book</h3>
          <p> You're on the final step. Only a few more minutes to finish!</p>
        </div>

        <div className="flightBooking-flex-container">
          <div className="container-one">
            <h4>
              <IoMdCheckmark /> Price Match Guarantee
            </h4>
            <h4>
              <IoMdCheckmark /> You're getting best airfares for this
              combination
            </h4>
          </div>

          <div className="container-two">
            <h4>
              <IoMdCheckmark /> 100% Secure & Easy Booking
            </h4>
            <h4>
              <IoMdCheckmark /> 24x7 Live Support
            </h4>
          </div>
        </div>

        <div className="flighBooking-itenary-container">
          <div className="itenarys-section">
            <p className="depart-p">Depart</p>
            <p style={{ textTransform: 'uppercase' }}>
              {flightData.origin}- {flightData.destination}
            </p>
          </div>
        </div>

        <div className="flighBooking-itenary-details">
          <div className="itenary-price-details">
            <img src={flightData.airlineImgSrc} alt="airline logo" style={{ width: "85px" }} />
            <p>{flightData.flightNumber}</p>
          </div>
          <div className="itenary-price-details">
            <h6>{flightData.arrivalDateTime}</h6>
            <p style={{ textTransform: 'uppercase' }}>{flightData.origin}</p>
          </div>
          <div className="itenary-price-details">
            <h6>{flightData.departureDateTime}</h6>
            {/* <h6>03:15 PM  Tue, Apr 30</h6> */}
            <p style={{ textTransform: 'uppercase' }}>{flightData.destination}</p>
          </div>
          <div className="itenary-price-details">
            <h6>{flightData.flightClass}</h6>
            {/* <p> 15 hrs 00 min</p> */}
          </div>
        </div>

        <div className="cost-break-down-main-section">
          <div className="const-break-dowon-main-container">
            <div className="cost-header">
              <h4>2. Cost breakdown</h4>
            </div>
          </div>

          <div className="cost-details-container">
            <div className="cost-passenger-details">
              <h6>Passenger</h6>
              <h6>Base Fare</h6>
              <h6>Taxes & Fee</h6>
              <h6>Total Fare</h6>
            </div>

            <div className="cost-passenger-details">
              <p>Total Passenger {totalPassenger}</p>
              <p>{flightData.baseFare}</p>
              <p>${flightData.totalTax}</p>
              <p className="fi">${flightData.totalFare}</p>
            </div>

            <div className="cost-passenger-details">
              <div className="cost-final-price-detail">
                <div className="h4-tag-det">
                  <h6>Final Total Fare</h6>
                  <p>(Includes Taxes & Fees)</p>
                </div>
                <p className="fi">${flightData.totalFare}</p>
              </div>
            </div>
            <h6 className="note">
              <span>NOTE</span>: All fares are in US Dollars (USD) including
              taxes and fees. Additional Baggage fees may apply.
            </h6>
          </div>
        </div>

        <div className="cost-break-down-main-section">
          <div className="const-break-dowon-main-container">
            <div className="cost-header-part-three">
              <h4>3. Who's flying?</h4>
              <p>(Please make sure names match government-issued IDs)</p>
            </div>
          </div>

          <AdultForm onFormSubmit={handleAdultFormSubmit} totalPassenger={totalPassenger} />
        </div>

        <div className="cost-break-down-main-section">
          <div className="const-break-dowon-main-container">
            <div className="cost-header-part-three">
              <h4>5. How do we contact you?</h4>
            </div>
          </div>

          <div className="form-section">
           
            <div className="contact-form">
              <div className="form-row">
                <div className="form-group">
                  <label>Email Address*</label>
                  <input type="email" value={email} onChange={handleEmailChange} required />
                </div>
                <div className="form-group">
                  <label>Re-Enter Email Address*</label>
                  <input type="email" placeholder="Re-Enter Mail" required />
                </div>
                <div className="form-group">
                  <label>Mobile Number*</label>
                  <input type="number" value={phone} onChange={handlePhoneChange} required />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="cost-break-down-main-section">
          <div className="const-break-dowon-main-container">
            <div className="cost-header-part-three">
              <h4>6. Payment Details</h4>
            </div>
          </div>

          <PaymentDetails onFormSubmit={handlePaymentDetailsSubmit} isCheckboxChecked={isCheckboxChecked} setIsCheckboxChecked={setIsCheckboxChecked} />
        </div>
        <div className="booking-btn" >
          <button  className="button-hover" type="submit" onClick={handleBookFlight}>Complete Booking <FaLock/></button>
        
        </div>
        <div className="image-visa-container">
          <img src={visa} alt="" />
        </div>
      </div>
    </div>
   </>
  );
};

export default FlightBooking;