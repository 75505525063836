import React from 'react';
import '../Pages/Loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="wait">Searching for the best flights...</div>
        <div className="iata_code departure_city">Planet</div>
        <div className="plane">
          <img src="https://zupimages.net/up/19/34/4820.gif" className="plane-img" alt="plane" />
        </div>
        <div className="earth-wrapper">
          <div className="earth"></div>
        </div>
        <div className="iata_code arrival_city">Fares</div>
      </div>
    </div>
  );
};

export default Loader;
