import React, { useState, useEffect } from "react";
import "./list.css";
import "./filter.css";
import { useLocation } from "react-router-dom";
import SearchItem from "./SearchItem";
import Loader from "../Loader";
import NotFound from "../Not Found/NotFound";
import axios from "axios";
import Header from "../../HomeComponents/Header/Header";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const List = () => {
  const location = useLocation();

  const [destination, setDestination] = useState(location.state.goingTo);
  const [origin, setOrigin] = useState(location.state.leavingFrom);
  const [adult, setAdult] = useState(location.state.numAdults);
  const [searchResult, setSearchResult] = useState(location.state.searchResult);
  const [filteredResults, setFilteredResults] = useState(location.state.searchResult);
  const [options, setOptions] = useState(location.state.options);
  const [flightTripp, setFlightTripp] = useState(location.state.flightTripp);

  const [isOneWay, setIsOneWay] = useState(location.state.isOneWay);
  const [numAdults, setNumAdults] = useState(location.state.options.numAdults);
  const [numChildren, setNumChildren] = useState(location.state.options.numChildren);
  const [numInfants, setNumInfants] = useState(location.state.options.numInfants);
  const [flightClass, setFlightClass] = useState(location.state.flightClass);
  const [loadingResults, setLoadingResults] = useState(false);
  const [departureDate, setDepartureDate] = useState(location.state.departureDate);
  const [returningDate, setReturningDate] = useState(location.state.returningDate);
  const [flightImg, setFlightImg] = useState(location.state.flightImg);
  const [pagination, setPagination] = useState(location.state.pagination.totalPage || 1);
  const [currentPage, setCurrentPage] = useState(location.state.pagination.currentPage || 1);
  const [sortOption, setSortOption] = useState(""); // Add sortOption state


  // console.log("pagination", location.state.pagination)
  // console.log("paginat", pagination)
  // console.log("paginationcu", currentPage)
  // console.log("paginationcutrr", flightTripp)

  const [filters, setFilters] = useState({
    bags: [],
    stops: [],
    price: [0, 10000],
    airline: [],
  });



  useEffect(() => {
    applyFilters();
  }, [filters, sortOption]);  // <-- Include sortOption here

  useEffect(() => {
    if (filteredResults.length > 0) {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  }, [filteredResults]);


  const handlePageChange = async (event, newPage) => {
    if (flightTripp === "OneWay") {
      await handleSearchOneway(newPage);
    } else {
      await handleSearchRoundtrip(newPage);
    }
    setCurrentPage(newPage); // Update currentPage state
  };




  const handleSearchOneway = async (newPage) => {
    setLoadingResults(true);

    try {
      // const originAirportCode = leavingFrom.split(" ")[0];
      // const destinationAirportCode = goingTo.split(" ")[0];

      const response = await axios.post("https://planetfares.onrender.com/api/proxy/agoda/oneway", {
        currency: "USD",
        departureDate,
        returnDate: returningDate || "",
        origin: origin,
        destination: destination,
        cabinType: flightClass,
        adults: numAdults,
        children: numChildren,
        infants: numInfants,
        page: newPage,
      });

      if (response.data) {
        // console.log("new data",response.data);
        // const fareItineraries = response.data.data.bundles|| [];
        setSearchResult(response.data.data.bundles || []);
        setFilteredResults(response.data.data.bundles || []);
        setPagination(response.data.meta.totalPage || {});
        setCurrentPage(response.data.meta.currentPage);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingResults(false);
    }
  };

  const handleSearchRoundtrip = async (newPage) => {
    setLoadingResults(true);

    try {
      // const originAirportCode = leavingFrom.split(" ")[0];
      // const destinationAirportCode = goingTo.split(" ")[0];

      const response = await axios.post("https://planetfares.onrender.com/api/proxy/agoda/oneway", {
        currency: "USD",
        departureDate,
        returnDate: returningDate || "",
        origin: origin,
        destination: destination,
        cabinType: flightClass,
        adults: numAdults,
        children: numChildren,
        infants: numInfants,
        page: newPage,
      });

      if (response.data) {
        // console.log("round trip",response.data);
        // const fareItineraries = response.data.data.bundles|| [];
        setSearchResult(response.data.data.bundles || []);
        setFilteredResults(response.data.data.bundles || []);
        setPagination(response.data.meta.totalPage || {});
        setCurrentPage(response.data.meta.currentPage);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingResults(false);
    }
  };


  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const resetFilter = (filterType) => {
    const resetValue = filterType === 'price' ? [0, 10000] : [];
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: resetValue,
    }));
  };

  const toggleChip = (filterType, value) => {
    handleFilterChange(
      filterType,
      filters[filterType].includes(value)
        ? filters[filterType].filter((v) => v !== value)
        : [...filters[filterType], value]
    );
  };

  //Filter by Chespest,Fastest,Best

  const applyFilters = () => {
    let filtered = searchResult;

    // Apply sorting based on the selected option
    if (sortOption === "Cheapest") {
      filtered = filtered.sort((a, b) => {
        const aPrice = a.bundlePrice?.[0]?.price?.usd?.display?.averagePerPax?.allInclusive || 0;
        const bPrice = b.bundlePrice?.[0]?.price?.usd?.display?.averagePerPax?.allInclusive || 0;
        return aPrice - bPrice;
      });
    } else if (sortOption === "Fastest") {
      filtered = filtered.sort((a, b) => a.totalTripDuration - b.totalTripDuration);
    } else if (sortOption === "Best") {
      filtered = filtered.sort((a, b) => {
        const aPrice = a.bundlePrice?.[0]?.price?.usd?.display?.averagePerPax?.allInclusive || 0;
        const aDuration = a.totalTripDuration || 0;
        const bPrice = b.bundlePrice?.[0]?.price?.usd?.display?.averagePerPax?.allInclusive || 0;
        const bDuration = b.totalTripDuration || 0;
        const aScore = aPrice + aDuration;
        const bScore = bPrice + bDuration;
        return aScore - bScore;
      });
    }

    


    // const handleSortChange = (option) => {
    //   setSortOption(option);
    // };



    // Filter by stops
    if (filters.stops.length > 0 && !filters.stops.includes('any')) {
      filtered = filtered.filter((item) =>
        filters.stops.includes(
          item.outboundSlice.segments.length - 1 === 0
            ? 'non stop'
            : `${item.outboundSlice.segments.length - 1} stop`
        )
      );
    }


    // Filter by price
    filtered = filtered.filter(
      (item) =>
        item.bundlePrice[0].price.usd.display.averagePerPax.allInclusive >= filters.price[0] &&
        item.bundlePrice[0].price.usd.display.averagePerPax.allInclusive <= filters.price[1]
    );

    // Filter by airline
    if (filters.airline.length > 0) {
      filtered = filtered.filter((item) =>
        filters.airline.includes(item.outboundSlice.segments[0].carrierContent.carrierName)
      );
    }

    setFilteredResults(filtered);
  };

  const getAirlines = () => {
    const airlines = new Set();
    searchResult.forEach((item) => {
      airlines.add(item.outboundSlice.segments[0].carrierContent.carrierName);
    });
    return Array.from(airlines);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Header />

      <div className="listContainer">
        <div className="listWrapper">
          <div className="filter-section">
            <div className="filter-group">
              <div className="filter-group-header">
                <h3>{Object.values(filters).flat().length} filters Active</h3>
                <button
                  className="reset-button"
                  onClick={() =>
                    setFilters({
                      bags: [],
                      stops: [],
                      price: [0, 10000],
                      airline: [],
                    })
                  }
                >
                  Reset
                </button>
              </div>
              <div className="filter-options">
                <span
                  className={`filter-chip ${filters.airline.length > 0 ? 'active' : ''}`}
                  onClick={() => toggleChip('airline', 'Airline')}
                >
                  Airline
                </span>
                <span
                  className={`filter-chip ${filters.price[0] !== 0 || filters.price[1] !== 10000 ? 'active' : ''}`}
                  onClick={() => toggleChip('price', 'Price')}
                >
                  Price
                </span>
                <span
                  className={`filter-chip ${filters.stops.length > 0 ? 'active' : ''}`}
                  onClick={() => toggleChip('stops', 'Stops')}
                >
                  Stops
                </span>
              </div>
            </div>

            <div className="filter-group">
              <div className="filter-group-header">
                <h3>Stops</h3>
                <button className="reset-button" onClick={() => resetFilter('stops')}>
                  Reset
                </button>
              </div>
              <div className="filter-options">
                <span
                  className={`filter-chip ${filters.stops.includes('any') ? 'active' : ''}`}
                  onClick={() => toggleChip('stops', 'any')}
                >
                  Any
                </span>
                <span
                  className={`filter-chip ${filters.stops.includes('non stop') ? 'active' : ''}`}
                  onClick={() => toggleChip('stops', 'non stop')}
                >
                  Non-stop
                </span>
                <span
                  className={`filter-chip ${filters.stops.includes('1 stop') ? 'active' : ''}`}
                  onClick={() => toggleChip('stops', '1 stop')}
                >
                  1 stop
                </span>
                <span
                  className={`filter-chip ${filters.stops.includes('2 stop') ? 'active' : ''}`}
                  onClick={() => toggleChip('stops', '2 stop')}
                >
                  2 stop
                </span>
              </div>
            </div>
            <div className="filter-group">
              <div className="filter-group-header">
                <h3>Price</h3>
                <button className="reset-button" onClick={() => resetFilter('price')}>
                  Reset
                </button>
              </div>
              <div className="price-range">
                <input
                  type="range"
                  min="0"
                  max="10000"
                  value={filters.price[1]}
                  onChange={(e) =>
                    handleFilterChange('price', [filters.price[0], parseInt(e.target.value)])
                  }
                />
                <div className="rangeprice">
                  <span className="leftt">${filters.price[0]}</span>
                  <span className="rightt">${filters.price[1]}</span>
                </div>
              </div>
            </div>

            <div className="filter-group">
              <div className="filter-group-header">
                <h3>Airline</h3>
                <button className="reset-button" onClick={() => resetFilter('airline')}>
                  Reset
                </button>
              </div>
              <div className="filter-options-column">
                {getAirlines().map((airline) => (
                  <div key={airline} className="filter-option">
                    <label className="label-m" htmlFor={airline}>{airline}</label>
                    <input
                      type="checkbox"
                      id={airline}
                      checked={filters.airline.includes(airline)}
                      onChange={() => toggleChip('airline', airline)}
                    />
                  </div>
                ))}
              </div>
            </div>

           
          </div>



          <div className="listResult">
            {loadingResults ? (
              <Loader />
            ) : filteredResults.length === 0 ? (
              <NotFound />
            ) : (
              filteredResults.map((item, index) => (
                <SearchItem
                  key={index}
                  item={item}
                  data={item}
                  totalFare={item.bundlePrice[0].price.usd.display.averagePerPax.allInclusive}
                  totalStops={item.outboundSlice.segments.length - 1}
                  validatingAirlineCode={item.outboundSlice.segments[0].carrierContent.carrierCode}
                  origin={origin}
                  destination={destination}
                  flightImg={flightImg}
                  flightClass={flightClass}
                  adults={numAdults}
                  childrens={numChildren}
                  infants={numInfants}
                />
              ))
            )}
          </div>


        </div>

      </div>
      <div className="pagination">
        <Stack spacing={3} alignItems="center">
          <Pagination
            count={pagination}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
            variant="outlined"
          />
        </Stack>
      </div>
    </div>
  );
};

export default List;
