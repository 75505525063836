import React, { useEffect, useState } from "react";
import "./Search.css";
import "../../Pages/Search/Search.css";
import originI from "../../Assets/origin.png";
import departureI from "../../Assets/departure.png";
import swap from "../../Assets/cross.png";
import calender from "../../Assets/date.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OriginTop from "../../Assets/orginn.png"
import Loader from "../../Pages/Loader";
import airportData from "../../Assets/airportData.json"; 

const SearchBar = () => {
  const [flightImg, setFlightImg] = useState([]);
  const [airport, setAirport] = useState([]);
  const [isOneWay, setIsOneWay] = useState(true);
  const [numAdults, setNumAdults] = useState(1);
  const [numChildren, setNumChildren] = useState(0);
  const [numInfants, setNumInfants] = useState(0);
  const [showPassengerOption, setShowPassengerOption] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leavingFrom, setLeavingFrom] = useState("");
  const [goingTo, setGoingTo] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returningDate, setReturningDate] = useState("");
  const [flightClass, setFlightClass] = useState("Economy");
  const [flightTripp, setFlightTripp] = useState("OneWay");

  const navigate = useNavigate();

  useEffect(() => {
    // Set the local JSON data to the airport state
    const AirportData = airportData.reduce((acc, curr) => {
      acc[curr.AirportCode] = curr.AirportName;
      return acc;
    }, {});
    setAirport(AirportData);
  }, []);

  const handleToggleShowPassenger = () => {
    setShowPassengerOption(!showPassengerOption);
  };

  const handleClosePassengerOptions = () => {
    setShowPassengerOption(false);
  };

  const handlePassengerChange = (type, increment) => {
    switch (type) {
      case "adults":
        setNumAdults((prevNum) => Math.max(prevNum + increment, 0));
        break;
      case "children":
        setNumChildren((prevNum) => Math.max(prevNum + increment, 0));
        break;
      case "infants":
        setNumInfants((prevNum) => Math.max(prevNum + increment, 0));
        break;
      default:
        break;
    }
  };

  const handleImage = async () => {
    try {
      const response = await axios.post("/api/proxy/flightImg", {
        user_id: "airlinesservices_testAPI",
        user_password: "airlinesservicesTest@2024",
        access: "Test",
        ip_address: "103.164.211.134",
      });

      if (response.data) {
        const imageData = response.data.reduce((acc, curr) => {
          acc[curr.AirLineCode] = curr.AirLineLogo;
          return acc;
        }, {});
        setFlightImg(imageData);
        return imageData;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async () => {
    if (flightTripp === "OneWay") {
      handleSearchOneway();
      // handleSearchRoundtrip();
    } else {
      handleSearchRoundtrip();
    }
  };

  const handleSearchRoundtrip = async (page = 1) => {
    setLoading(true);

    try {
      const originAirportCode = leavingFrom.split(" ")[0];
      const destinationAirportCode = goingTo.split(" ")[0];

      const response = await axios.post("https://planetfares.onrender.com/api/proxy/agoda/roundtrip", {
        currency: "USD",
        departureDate,
        returnDate: returningDate || "",
        origin: originAirportCode,
        destination: destinationAirportCode,
        cabinType: flightClass,
        adults: numAdults,
        children: numChildren,
        infants: numInfants,
        page:page,
       
      });

      if (response.data) {
        // console.log(response.data.data);
        const fareItineraries = response.data.data.bundles|| [];
        // console.log("fare", fareItineraries);
        navigate("/flights", {
          state: {
            searchResult: fareItineraries,
            leavingFrom:originAirportCode,
            departureDate,
            returningDate,
            goingTo:destinationAirportCode,
            flightClass,
            options: { numAdults, numChildren, numInfants },
            flightTripp:flightTripp,
            pagination: response.data.meta,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchOneway = async (page = 1) => {
    setLoading(true);

    try {
      const originAirportCode = leavingFrom.split(" ")[0];
      const destinationAirportCode = goingTo.split(" ")[0];

      const response = await axios.post("https://planetfares.onrender.com/api/proxy/agoda/oneway", {
        currency: "USD",
        departureDate,
        returnDate: returningDate || "",
        origin: originAirportCode,
        destination: destinationAirportCode,
        cabinType: flightClass,
        adults: numAdults,
        children: numChildren,
        infants: numInfants,
        page:page,
      });

      if (response.data) {
        // console.log(response.data);
        const fareItineraries = response.data.data.bundles|| [];
        navigate("/flights", {
          state: {
            searchResult: fareItineraries,
            leavingFrom:originAirportCode,
            departureDate,
            returningDate,
            goingTo:destinationAirportCode,
            flightClass,
            options: { numAdults, numChildren, numInfants },
            pagination: response.data.meta,
            flightTripp:flightTripp,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const preventDefaultAction = (e) => {
    e.preventDefault();
  };


  return (
    <div>
    <div className="topSe">
      <div className="topsee">
      <img src={OriginTop} alt=""  />
      </div>
      <div className="topsee">
      <h3>Flights</h3>
      </div>
    </div>
    <div
      className="search-bar"
      
    >
      <select className="trip-type" value={flightTripp}
      onChange={(e) => setFlightTripp(e.target.value)}>
        <option value="RoundTrip">Round Trip</option>
        <option value="OneWay">One Way</option> 
        {/* <option value="Return">Return</option> */}
      </select>

      {/* <select className="trip-type" placeholder="passenger" onClick={handleToggleShowPassenger}> */}
      <option
  onClick={handleToggleShowPassenger}
  value={numAdults + numChildren + numInfants}
>
  Passenger: {numAdults + numChildren + numInfants}
</option>


      {showPassengerOption && (
        <div className="passenger-optionss">
           <button
              className="close-btn"
              onClick={handleClosePassengerOptions}
            >
              &times;
            </button>
          <div className="passenger-type">
            <label>Adults:</label>
            <div className="addOrSubBtn">
              <button
                onClick={(e) => {
                  preventDefaultAction(e);
                  handlePassengerChange("adults", -1);
                }}
              >
                -
              </button>
              <span>{numAdults}</span>
              <button
                onClick={(e) => {
                  preventDefaultAction(e);
                  handlePassengerChange("adults", 1);
                }}
              >
                +
              </button>
            </div>
          </div>
          <div className="passenger-type">
            <label>Children:</label>
            <div className="addOrSubBtn">
              <button
                onClick={(e) => {
                  preventDefaultAction(e);
                  handlePassengerChange("children", -1);
                }}
              >
                -
              </button>
              <span>{numChildren}</span>
              <button
                onClick={(e) => {
                  preventDefaultAction(e);
                  handlePassengerChange("children", 1);
                }}
              >
                +
              </button>
            </div>
          </div>
          <div className="passenger-type">
            <label>Infants:</label>
            <div className="addOrSubBtn">
              <button
                onClick={(e) => {
                  preventDefaultAction(e);
                  handlePassengerChange("infants", -1);
                }}
              >
                -
              </button>
              <span>{numInfants}</span>
              <button
                onClick={(e) => {
                  preventDefaultAction(e);
                  handlePassengerChange("infants", 1);
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      )}

      <select
        className="class-type"
        value={flightClass}
        onChange={(e) => setFlightClass(e.target.value)}
      >
        <option style={{backgroundColor:'white',color:'black'}} value="Economy">Economy</option>
        <option style={{backgroundColor:'white',color:'black'}} value="PremiumEconomy">Premium Economy</option>
        <option style={{backgroundColor:'white',color:'black'}} value="Business">Business</option>
        <option style={{backgroundColor:'white',color:'black'}} value="First">First</option>
      </select>

      <div className="origin-d">
        <div className="input-icon">
          {/* <FontAwesomeIcon icon={faPlane} /> */}
          <img src={originI} alt="" className="reo"/>
          <input
            type="text"
            className="origin"
            placeholder="Enter Origin"
            value={leavingFrom}
            onChange={(e) => setLeavingFrom(e.target.value)}
            list="fromAirports"
            required
          />
         <datalist id="fromAirports">
  {Object.entries(airport).map(([code, city]) => (
    <option key={code} value={`${code} (${city})`} />
  ))}
</datalist>

        </div>
        <div className="input-iconn">
          <img src={swap} alt="" />
        </div>
        <div className="input-icon">
          <img src={departureI} alt="" className="reo"/>
          <input
            type="text"
            className="destination"
            placeholder="Enter Destination"
            value={goingTo}
            onChange={(e) => setGoingTo(e.target.value)}
            list="toAirports"
            required
          />
          <datalist id="toAirports">
            {Object.entries(airport).map(([code, city]) => (
              <option key={code} value={`${code} (${city})`} />
            ))}
          </datalist>
        </div>
        <div className="input-icon">
          {/* <img src={calender} alt='' /> */}
          <input
            type="date"
            className="depart-date"
            placeholder="Depart"
            value={departureDate}
            required
            onChange={(e) => setDepartureDate(e.target.value)}
            
          />
        </div>
        <div className="input-icon">
          {/* <img src={calender} alt='' /> */}
          <input
            type="date"
            className="return-date"
            placeholder="Return"
            value={returningDate}
            onChange={(e) => setReturningDate(e.target.value)}
          />
          {/* <input placeholder="Date" class="textbox-n" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" id="date" /> */}
        </div>
        <button className="search-btnn" type="submit" onClick={handleSearch}>
  <div className="svg-wrapper-1">
    <div className="svg-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          fill="currentColor"
          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
        ></path>
      </svg>
    </div>
  </div>
  <span>Search</span>
</button>


      
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
          }}
        >
          <Loader />
        </div>
      )}
    </div>
    </div>
  );
};

export default SearchBar;