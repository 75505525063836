import React from 'react';
import './HeroSection.css';
import SearchBar from '../Search/SearchBar';
const vide = require('../../Assets/2078401-uhd_3840_2160_30fps.mp4');

const HeroSection = () => {
  return (
    <div className="hero-section">
      <video className="background-video" autoPlay loop muted>
        <source src={vide} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlayy">
        <h1>FIND CHEAP FLIGHTS AND SAVE</h1>
        <p>SEARCH AND COMPARE OUR BEST DEALS WITH OVER 440+ AIRLINES - BOOK NOW</p>
        <SearchBar />
      </div>
    </div>
  );
};

export default HeroSection;
