import React, { useState } from "react";
import "./Header.css";
import { GiAirplaneDeparture } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
import { PiDotsNineBold } from "react-icons/pi";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Link } from "react-router-dom";
import UserOptions from "../../Components/Header/userOptions";
import { useSelector } from "react-redux";
import Loader from "../../Pages/Loader";
import { FaEarthAmericas } from "react-icons/fa6";

const logo = require('../../Assets/PNG.png');

const Header = () => {
  const { isAuthenticated, user, loading } = useSelector((state) => state.user);
  const [active, setActive] = useState("navBar");

  const showNavbar = () => {
    setActive("navBar activeNavbar");
  };

  const removeNavbar = () => {
    setActive("navBar");
  };

  return (
    <>
      {loading ? <Loader /> : (
        <section className="navBarSection">
          <header className="home-header flex">
            <div className="logoDiv">
              <Link to={"/"} className="logo flex">
                <div className="nav-logo">
                  <img src={logo} alt="Logo" />
                </div>
              </Link>
            </div>
            <div className={active}>
              <ul className="navLists flex">
                <li className="navItem">
                  <Link to={'/myTrip'} className="navLink">
                    My Trips
                  </Link>
                </li>
                <li className="navItem">
                  <Link to={"/contactUs"} className="navLink">
                    Contact
                  </Link>
                </li>
                <li className="navItem">
                  <Link to={"/aboutUs"} className="navLink">
                    About Us
                  </Link>
                </li>
                <li className="navItem">
                  <Link to={""} className="navLink">
                    <FaEarthAmericas style={{ marginRight: '0.5rem', textAlign: 'center' }} />
                    Us
                  </Link>
                </li>
                <button type="submit" className="Btnn">
                  <BiSolidPhoneCall className="icon" />
                  (844) 417-7506
                </button>
              </ul>
              <div onClick={removeNavbar} className="closeNavbar">
                <AiFillCloseCircle className="navbarIcon" />
              </div>
            </div>
            <div onClick={showNavbar} className="toggleNavbar">
              <PiDotsNineBold className="navbarIcon" />
            </div>
            {isAuthenticated && <UserOptions user={user} />}
          </header>
        </section>
      )}
    </>
  );
};

export default Header;
