import React, { useEffect, useState } from 'react';
import './SignIn.css';
import { MdLockOutline, MdOutlineMail } from 'react-icons/md';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import { AiFillApple } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, login } from '../../Action/userAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';

const image = require('../../Assets/Images/Rectangle 26.png');
const imageTwo = require('../../Assets/Images/Vector.png');
const imageThree = require('../../Assets/Images/Group 688.png');

const SignIn = () => {
  const { error, loading, isAuthenticated } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const loginSubmit = e => {
    e.preventDefault();
    dispatch(login(loginEmail, loginPassword));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      toast.success('Login Successful');
      navigate('/');
    }
  }, [isAuthenticated, error, dispatch, navigate]);

  return (
    <>
      {loading ? <Loader /> : (
        <div className="login-page">
          <div className="left-section">
            <img src={image} alt="Flight Booking" className="booking-image" />
            <div className="left-text">
              <h1>Welcome To Airline</h1>
              <p>Travel is the only purchase that enriches you in ways beyond material wealth</p>
            </div>
          </div>
          <div className="right-section">
            <div className="plane-img-container">
              <img src={imageTwo} alt="" />
            </div>
            <h1>Welcome</h1>
            <p>Login with Email</p>
            <form onSubmit={loginSubmit} className="login-form">
              <label htmlFor="email">Email Id</label>
              <div className="input-container">
                <MdOutlineMail className="icon" />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={loginEmail}
                  onChange={e => setLoginEmail(e.target.value)}
                  placeholder="thisisuk@mail.com"
                />
              </div>
              <label htmlFor="password">Password</label>
              <div className="input-container">
                <MdLockOutline className="icon" />
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={loginPassword}
                  onChange={e => setLoginPassword(e.target.value)}
                />
              </div>
              <a href="#" className="forgot-password">Forgot your password?</a>
              <button type="submit">LOGIN</button>
            </form>
            <div className="or-divider">---------- OR ----------</div>
            <div className="social-login">
              <button className="google-login"><FcGoogle /></button>
              <button className="facebook-login"><FaFacebook /></button>
              <button className="apple-login"><AiFillApple /></button>
            </div>
            <p>Don’t have an account? <Link to={'/signUp'}>Register Now</Link></p>
            <div className="three-container">
              <img src={imageThree} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
