import React from "react";
import "./ReviewSection.css";
import user1 from "../../Assets/Images/user-1.png";
import user2 from "../../Assets/Images/user-2.png";
import user3 from "../../Assets/Images/user-3.png";
import user4 from "../../Assets/Images/user-4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

const testimonials = [
  {
    id: 1,
    name: "John Doe",
    image: user1,
    stars: 5,
    review:
      " Marcus was again exceptional. He took care of worries in detailed and caring ways. T...",
  },
  {
    id: 2,
    name: "Jane Smith",
    image: user2,
    stars: 4,
    review:
      " Marcus was again exceptional. He took care of worries in detailed and caring ways. T...",
  },
  {
    id: 3,
    name: "Alice Johnson",
    image: user3,
    stars: 3,
    review:
      " Marcus was again exceptional. He took care of worries in detailed and caring ways. T...",
  },
  {
    id: 4,
    name: "Bob Williams",
    image: user4,
    stars: 4,
    review:
      " Marcus was again exceptional. He took care of worries in detailed and caring ways. T...",
  },
  {
    id: 5,
    name: "Bob Williams",
    image: user4,
    stars: 4,
    review:
      " Marcus was again exceptional. He took care of worries in detailed and caring ways. T...",
  },
  {
    id: 6,
    name: "Bob Williams",
    image: user4,
    stars: 4,
    review:
      " Marcus was again exceptional. He took care of worries in detailed and caring ways. T...",
  },
  // Add more testimonials as needed
];

const ReviewSection = () => {
  return (
    <div className="reviewSection-container">
      <div className="carousel-container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          breakpoints={{
            800: {
              slidesPerView: 4,
            },
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide>
              <div key={testimonial.id} className="carousel-group">
                <div className="upper-carousel-content">
                  <div className="nameAndStart">
                    <div className="stars">
                      {[...Array(testimonial.stars)].map((_, index) => (
                        <span key={index} className="star">
                          &#9733;
                        </span>
                      ))}
                    </div>
                    <div className="reviewerName">Verified</div>
                  </div>
                </div>
                <div className="lower-carousel-content">
                  <h6>Marcus Was Exeptional</h6>
                  <p>{testimonial.review}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="footer">
        <p>
          Rated 3.9 / 5 based on 394 reviews. Showing our 4 & 5 star reviews.
        </p>
        <p className="trustpilot-btnn">
          <span  style={{color:"#00b67a;"}}>&#9733;</span>Trustpilot
        </p>
      </div>
    </div>
  );
};

export default ReviewSection;
