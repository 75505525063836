// BookingReview.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import FlightCard from './FlightCard';
import PriceDetails from './PriceDetails';
import BaggageInfo from './BaggageInfo';
import './BookingReview.css';
import Header from '../../HomeComponents/Header/Header';

const BookingReview = ({returnDatee}) => {
  const location = useLocation();
  const { flightData } = location.state || {};

  if (!flightData) {
    return <div>No flight details available.</div>;
  }

  return (
    <>
      <Header />
      <div className="booking-review">
        <h1>Review Your Booking</h1>
        <div className="booking-section">
          <div className="flight-cards">
            <FlightCard type="Departure" flightData={flightData} />
           <FlightCard type="Return" flightData={flightData} />
          </div>
          <div className="pdetailsS">
          <PriceDetails flightData={flightData} />
          </div>
        </div>
        <BaggageInfo flightData={flightData} />
      </div>
    </>
  );
};

export default BookingReview;
