import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearErrors } from '../../Action/userAction';
import Loader from '../Loader';
import Header from '../../HomeComponents/Header/Header';
import './Mytrip.css';

const img = require('../../Assets/Images/fmt-cover.jpg');

const Mytrip = () => {
  const { error, loading, isAuthenticated } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  return (
    <>
      <Header />
      {loading ? <Loader /> : (
        <div className="mytrip-main-container">
          <div className="mytrip-semi-main-container">
            <div className="mytrip-container">
              <div className="mytrip-left-container">
                <img className="mytrip-img" src={img} alt="Trip" />
              </div>
              <div className="mytrip-right-container">
                <div className="mytrip-header">
                  <h1>Find my Booking</h1>
                </div>
                <div className="mytrip-para">
                  <ul>
                    <li className="mytrip-li">The Order ID can be found in your booking confirmation email or e-Ticket.</li>
                    <li className="mytrip-li">The last name should belong to one of the passengers from the booking.</li>
                  </ul>
                </div>
                <form className="mytrip-form" action="">
                  <div>
                    <label htmlFor="orderId" className="custom-label">Order Id</label>
                    <input id="orderId" className="custom-width-input" type="text" placeholder="Wxxxxxx" required />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="custom-label">Passenger's last name</label>
                    <input id="lastName" className="custom-width-input" type="text" placeholder="Last Name" required />
                  </div>
                  <div>
                    <label htmlFor="email" className="custom-label">Contact Email</label>
                    <input id="email" className="custom-width-input" type="email" placeholder="Email" required />
                  </div>
                  <button className="my-trip-btn" type="submit">Continue</button>
                </form>
                {!isAuthenticated && (
                  <p className="mytrip-p">Have an account? <Link to={'/signIn'} className="mytrip-link">Sign In</Link></p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Mytrip;
