import React from 'react';
import './BaggageInfo.css';
import {Link} from 'react-router-dom'

import { GiRollingSuitcase } from "react-icons/gi";

const BaggageInfo = () => {
  return (
    <div className="baggage-info">
      <h2>Baggage Information (Per Person)</h2>
      <div className="baggage-item">
        <div className="baggage-header">
          <h3>BBI → JFK</h3>
          <p>Vistara | Cabin: Coach | Brand Name: Economy Light</p>
        </div>
        <div className="baggage-details">
          <div className="baggage-detail">
            <span className='bag-icon' role="img" aria-label="lock"><GiRollingSuitcase/></span>
            <div>
              <p className="item-title">Personal Item</p>
              <p className="item-description">Purse, Small Backpack, Briefcase</p>
              <p className="item-status">✔ Included</p>
            </div>
          </div>
          <div className="baggage-detail">
            <span className='bag-icon' role="img" aria-label="lock"><GiRollingSuitcase/></span>
            <div>
              <p className="item-title">Personal Item</p>
              <p className="item-description">Purse, Small Backpack, Briefcase</p>
              <p className="item-status">✔ Included</p>
            </div>
          </div>
          <div className="baggage-detail">
            <span className='bag-icon' role="img" aria-label="lock"><GiRollingSuitcase/></span>
            <div>
              <p className="item-title">Personal Item</p>
              <p className="item-description">Purse, Small Backpack, Briefcase</p>
              <p className="item-status">✔ Included</p>
            </div>
          </div>
        </div>
        <Link className='a' to={'/'}>
          Cathay Pacific Baggage Policy <span role="img" aria-label="link">🔗</span>
        </Link>
      </div>
    </div>
  );
};

export default BaggageInfo;
