// FlightCard.js
import React from 'react';
import './FlightCard.css';

const airlineLogo = require('../../Assets/Images/Ellipse 3.png');

const FlightCard = ({ type, flightData }) => {

  const departureTime = new Date(flightData.departureDateTime);
  const arrivalTime = new Date(flightData.arrivalDateTime);
  const durationMs = arrivalTime - departureTime; // duration in milliseconds

  const hours = Math.floor(durationMs / (1000 * 60 * 60));
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

  const formattedDuration = `${hours}h ${minutes}m`;
  return (
    <div className="flight-card">
      <div className="flight-type">
        <button className="flight-type-btn">{type}</button>
        <span className="flight-route">{`${flightData.origin} - ${flightData.destination}`}</span>
      </div>
      <p className="local-time">All Departure/Arrival Times Are In Local Time</p>
      <div className="flight-details">
        <div className="airline-info">
          <img src={flightData.airlineImgSrc} alt={`${flightData.flightName} Logo`} />
          <span>{`${flightData.flightName} | ${flightData.flightNumber}`}</span>
        </div>
        <div className="flight-time">
          <span className="flight-start">Start On- <strong>{new Date(flightData.departureDateTime).toLocaleString()}</strong></span>
          <span className="flight-end">Arrive On- <strong>{new Date(flightData.arrivalDateTime).toLocaleString()}</strong></span>
        </div>
        <div className="time-duration">
          <span className="time">{flightData.formattedDepartureTime}</span>
          <span className="duration">{formattedDuration}</span> {/* You may want to calculate the actual duration */}
          <span className="time">{flightData.formattedArrivalTime}</span>
        </div>
        <div className="airport-info">
          <div className="departure">
            <span className="code">{flightData.origin.split(' ')[0]}</span>
            <span className="name">{flightData.origin}</span>
            <span className="full-name">Devi Ahilyabai Holkar International Airport, India</span>
          </div>
          <div className="arrival">
            <span className="code">{flightData.destination.split(' ')[0]}</span>
            <span className="name">{flightData.destination}</span>
            <span className="full-name">Indira Gandhi International Airport, India</span>
            <span className="terminal">Terminal T3</span>
          </div>
        </div>
        <div className="baggage-info">
          <span className="baggage-title">Baggage</span>
          <span className="baggage-detail">- 🧳 7 Kgs (1 Piece Only) Cabin 🔒 15 Kgs (1 Piece X 15 Kgs) Check-In</span>
        </div>
      </div>
    </div>
  );
};

export default FlightCard;
