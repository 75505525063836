//USER CONSTANT

export const LOGIN_REQUEST ="ALL_LOGIN_REQUEST";
export const LOGIN_SUCCESS="ALL_LOGIN_SUCCESS";
export const LOGIN_FAIL ="ALL_LOGIN_FAIL";


//REGISTER CONSTANT

export const REGISTER_REQUEST ="ALL_REGISTER_REQUEST";
export const REGISTER_SUCCESS="ALL_REGISTER_SUCCESS";
export const REGISTER_FAIL ="ALL_REGISTER_FAIL";




export const LOAD_USER_REQUEST ="LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS="LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL ="LOAD_USER_FAIL";


export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";


export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";



export const CLEAR_ERROR ="ALL_PRODUCT_ERROR";
export const CLEAR_MESSAGE ="ALL_PRODUCT_MESSAGE";