import React from "react";
import { useNavigate } from "react-router-dom";
import { IoAirplaneSharp } from "react-icons/io5";
import "./searchItem.css";
import Line from "../../Assets/Line.png";

const SearchItem = ({
  item,
  totalFare,
  totalStops,
  returnDate,
  validatingAirlineCode,
  origin,
  destination,
  flightImg,
  flightClass,
  adults,
  childrens,
  infants,
}) => {
  const lastInboundSegment =
    item.outboundSlice.segments[item.outboundSlice.segments.length - 1];
  const firstOutboundSegment = item.outboundSlice.segments[0];

  const arrivalDateTime = lastInboundSegment.arrivalDateTime;
  const departureDateTime = firstOutboundSegment.departDateTime;

  const flightNumber = firstOutboundSegment.flightNumber;
  const flightName = firstOutboundSegment.carrierContent.carrierName;

  const baseFare = item.bundlePrice[0].price.usd.display.baseFare;
  const totalTax = item.bundlePrice[0].price.usd.display.totalTaxes;

  const navigate = useNavigate();

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formattedArrivalTime = formatTime(arrivalDateTime);
  const formattedDepartureTime = formatTime(departureDateTime);
  const returnDatee = returnDate || 0;
  const airlineImgSrc =
    item.outboundSlice.segments[0].carrierContent.carrierIcon || "";

  const toggleDropdown = () => {
    navigate(`/flightDetails/${flightNumber}`, {
      state: {
        flightData: {
          flightName,
          flightNumber,
          totalFare,
          origin,
          destination,
          formattedArrivalTime,
          formattedDepartureTime,
          airlineImgSrc,
          validatingAirlineCode,
          totalTax,
          baseFare,
          arrivalDateTime,
          departureDateTime,
          flightClass,
          adults,
          childrens,
          infants,
        },
        returnDatee,
      },
    });
  };

  const handleBooking = () => {
    navigate(`/flightBooking/${flightNumber}`, {
      state: {
        flightData: {
          flightName,
          flightNumber,
          totalFare,
          origin,
          destination,
          formattedArrivalTime,
          formattedDepartureTime,
          airlineImgSrc,
          validatingAirlineCode,
          totalTax,
          baseFare,
          arrivalDateTime,
          departureDateTime,
          flightClass,
          adults,
          childrens,
          infants,
        },
      },
    });
  };

  return (
    <div className="searchItem">
      <div className="main-containerrR">
        <div className="siDesc">
          <img src={airlineImgSrc} alt="Loading.." className="siImg" />
          <h1
            className="siTitle"
            style={{ fontWeight: "400", fontSize: "20px" }}
          >
            {`${flightName} `}
            {/* amit */}
          </h1>
        </div>
        <div className="descriptionns">
          <div className="flexbox">
            <span className="siSubtitle" style={{ textTransform: "uppercase" }}>
              {formattedDepartureTime}
            </span>
            <span className="hr-tag">
              <img src={Line} alt="" />
            </span>
            <span className="siSubtitle" style={{ textTransform: "uppercase" }}>
              {formattedArrivalTime}
            </span>
          </div>
          <div className="flexbox-two">
            <span
              className="siCancelOp"
              style={{ color: "#000", fontSize: "15px" }}
            >
              {origin.split(" ")[0]}
            </span>
            <span className="siCancelSto">{totalStops} Stop</span>
            <span
              className="siCancelOp"
              style={{ color: "#000", fontSize: "15px" }}
            >
              {destination.split(" ")[0]}
            </span>
          </div>
        </div>
      </div>
      <div className="siDetails">
        <span className="siPrice">${totalFare}</span>
        <button className="siCheckButtonbook" onClick={handleBooking}>
          Book Now
        </button>
        <button className="siCheckButton" onClick={toggleDropdown}>
          Details
        </button>
      </div>
    </div>
  );
};

export default SearchItem;
