import React from "react";
import Header from "../../HomeComponents/Header/Header";
import ReviewSection from "../../Components/ReviewsSection/ReviewSection";
import ChooseUs from "../../Components/ChooseUS/Choose";
import PopularDestination from "../../Components/Destination/PopularDestination";
import TopRoutes from "../../Components/TopFlight/TopFlight";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PopularDeals from "../../Components/PopularDeal/PopularDeal";
import HeroSection from "../../Components/Hero/Hero";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleSearch = async (origin, destination) => {
    setLoading(true);
    // console.log("handleSearch called");
    try {
      const originAirportCode = origin.split(" ")[0];
      const destinationAirportCode = destination.split(" ")[0];
  
      // Get today's date
      let currentDate = new Date();
  
      // Set minimumDepartureDate to tomorrow's date
      currentDate.setDate(currentDate.getDate() + 1);
  
      // Function to format date as "YYYY-MM-DD"
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
  
      // Dynamically set the minimumDepartureDate in the required format
      const minimumDepartureDate = formatDate(currentDate);
  
      // Ensure departureDate is at least the minimumDepartureDate
      let departureDate = new Date(minimumDepartureDate);
  
      // Set return date to 2 days after departure date
      const returnDate = new Date(departureDate);
      returnDate.setDate(departureDate.getDate() + 2);
  
      const formattedDepartureDate = formatDate(departureDate);
      const formattedReturnDate = formatDate(returnDate);
  
      const response = await axios.post("https://planetfares.onrender.com/api/proxy/agoda/oneway", {
        currency: "USD",
        departureDate: formattedDepartureDate,
        returnDate: formattedReturnDate,
        origin: originAirportCode,
        destination: destinationAirportCode,
        cabinType: 'Economy',
        adults: 1,
        children: 0,
        infants: 0,
      });
  
      if (response.data) {
        const fareItineraries = response.data?.data?.bundles || [];
        // console.log(response.data);
        navigate("/flights", {
          state: {
            searchResult: fareItineraries,
            leavingFrom: origin,
            departureDate: formattedDepartureDate,
            returningDate: formattedReturnDate,
            goingTo: destination,
            flightClass: "Economy",
            options: { numAdults: 1, numChildren: 0, numInfants: 0 },
            pagination: response.data.meta,
            flightTripp:"OneWay",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="home-page">

      <Header />
      <HeroSection />


      <section className="reviewAndStarSection">
        <div className="bothSection">
          {/* <StarSection /> */}
          <h3 style={{ textAlign: "center", marginTop: "20px" }}>Here's why travelers <span style={{ color: "#00a58e" }}>choose Planet Fares</span></h3>
          <ChooseUs />
          <ReviewSection />
        </div>
      </section>



    
      <PopularDestination handleSearch={handleSearch} loading={loading} />
      <PopularDeals handleSearch={handleSearch} />
      <TopRoutes handleSearch={handleSearch} />
    </div>
  );
};

export default Home;
