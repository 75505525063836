import React, { useEffect, useState } from 'react';
import './SignUp.css';
import { MdLockOutline, MdOutlineMail } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";
import { AiFillApple } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, register } from '../../Action/userAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';

const image = require('../../Assets/Images/Rectangle 26.png');
const imageTwo = require('../../Assets/Images/Vector.png');
const imageThree = require('../../Assets/Images/Group 688.png');

const SignUp = () => {
  const { error, loading, isAuthenticated } = useSelector(state => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const registerSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      email: email,
      password: password
    };

    dispatch(register(formData));
  };

  
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      toast.success('Login Successful');
      navigate('/');
    }
  }, [isAuthenticated, error, dispatch, navigate]);

  return (
   <>
   {loading ? <Loader/> :(
     <div className="login-page">
     <div className="left-section">
       <img
         src={image} // replace with your actual image URL
         alt="Flight Booking"
         className="booking-image"
       />
       <div className="left-text">
         <h1>Welcome To Airline</h1>
         <p>Travel is the only purchase that enriches you in ways beyond material wealth</p>
       </div>
     </div>
     <div className="right-section">
       <div className="plane-img-contianer">
         <img src={imageTwo} alt="" />
       </div>
       <h1>Welcome</h1>
       <p>Register with Email</p>
       <form encType="multipart/form-data" onSubmit={registerSubmit} className="login-form">
         <label htmlFor="name">Name</label>
         <div className="input-container">
           <FaRegUser className="icon" />
           <input type="text" id="name" name="name" placeholder="Canon Winston" value={name} onChange={(e) => setName(e.target.value)} required />
         </div>
         <label htmlFor="email">Email Id</label>
         <div className="input-container">
           <MdOutlineMail className="icon" />
           <input type="email" id="email" name="email" placeholder="thisisuk@mail.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
         </div>
         <label htmlFor="password">Password</label>
         <div className="input-container">
           <MdLockOutline className="icon" />
           <input type="password" id="password" name="password" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)}required />
         </div>
         <button type="submit">Register</button>
         <p>Already have an account? <Link to={'/signIn'}>Sign In</Link></p>
       </form>
       <div className="or-divider">---------- OR ----------</div>
       <div className="social-login-r">
         <button className="google-login"><FcGoogle /></button>
         <button className="facebook-login"><FaFacebook /></button>
         <button className="apple-login"><AiFillApple /></button>
       </div>
     </div>
   </div>
   )}
   </>
  );
}

export default SignUp;