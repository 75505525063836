import React from 'react'
import './about.css'
import Header from '../../HomeComponents/Header/Header'
const aboutImg = require('../../Assets/Images/aboutUs.png')

const About = () => {
  return (
   <>
     <Header />
     <div className='about-main-container'>

       <div className="about-main-section">
         <div className="about-img-container">
           <img src={aboutImg} alt="About Us" />
           <div className="about-text-overlay">
             About Us
           </div>
         </div>

         <div className="about-header">
           <h3>Why Planet Fares?</h3>
         </div>

         <hr />

         <div className="about-des">
           <p>Planet Fares is your one-stop destination for booking affordable flight tickets across the globe. We specialize in providing the cheapest airfare options to ensure that our customers can travel without breaking the bank. Whether you're planning a domestic trip or an international adventure, we connect you to a vast network of flights, offering competitive prices and reliable service.</p>

           <p>Our platform is designed to be user-friendly, allowing you to seamlessly book flights, make changes to your itinerary, and manage your bookings with ease. With 24/7 support and access to exclusive deals, Planet Fares ensures that your travel experience is smooth from start to finish.</p>

           <p>We are committed to providing not just the cheapest flights, but also a high level of service and convenience. Our dedicated team works around the clock to bring you the best deals on flights, so you can focus on enjoying your journey. Choose Planet Fares for a hassle-free, budget-friendly travel experience!</p>
         </div>

         <div className="about-des-two">
           <p>At Planet Fares, we believe that travel should be accessible to everyone. That's why we offer flights at unbeatable prices, helping you explore new destinations without the financial stress. From family vacations to business trips, we have something for every traveler. With our vast selection of flights and exceptional customer service, we aim to make your next trip the best one yet.</p>
         </div>
       </div>
     </div>
   </>
  )
}

export default About
