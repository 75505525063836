import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './paymentDetails.css';

import { FaLock } from 'react-icons/fa';


const creditOne = require('../../Assets/Images/credit-card1.png');
const creditTwo = require('../../Assets/Images/credit-card2.png');
const creditThree = require('../../Assets/Images/credit-card4.png');
const creditFour = require('../../Assets/Images/credit-card5.png');

const PaymentDetails = ({ onFormSubmit,isCheckboxChecked, setIsCheckboxChecked }) => {
  
 
  const [formData, setFormData] = useState({
    creditCardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    cardholdersName: '',
    billingAddress: '',
    city: '',
    zipCode: '',
    billingPhone: '',
    country: '',
    state: '',
  });

  const handleFormSubmit = () => {
    
  
    onFormSubmit(formData);
  };

  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);
  useEffect(() => {
    
    const requiredFields = ['creditCardNumber', 'expiryMonth', 'expiryYear', 'cvv', 'cardholdersName', 'billingAddress', 'city', 'zipCode', 'billingPhone', 'country', 'state'];
    const isFormFilled = requiredFields.every(field => formData[field] !== '');
  
    if (isFormFilled) {
    
      handleFormSubmit(); 
    }
  }, [formData]);
  return (
    <div className="form-section">
    <form className="payment-form" onSubmit={handleFormSubmit}>
      <div className="form-row">
        <div className="form-group">
          <label>Credit Card*</label>
          <input
            type="number"
            placeholder="Credit Card#"
            value={formData.creditCardNumber}
            onChange={(e) => setFormData({ ...formData, creditCardNumber: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Expiry Month*</label>
          <select
            value={formData.expiryMonth}
            onChange={(e) => setFormData({ ...formData, expiryMonth: e.target.value })}
            required
          >
            <option value="">Month</option>
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Expiry Year*</label>
          <select
            value={formData.expiryYear}
            onChange={(e) => setFormData({ ...formData, expiryYear: e.target.value })}
            required
          >
            <option value="">Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>CVV*</label>
          <input
            type="number"
            placeholder="CVV"
            value={formData.cvv}
            onChange={(e) => setFormData({ ...formData, cvv: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>Cardholder's Name*</label>
          <input
            type="text"
            placeholder="Full Name"
            value={formData.cardholdersName}
            onChange={(e) => setFormData({ ...formData, cardholdersName: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Billing Address*</label>
          <input
            type="text"
            placeholder="Street Address"
            value={formData.billingAddress}
            onChange={(e) => setFormData({ ...formData, billingAddress: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>City*</label>
          <input
            type="text"
            placeholder="City"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>State*</label>
          <RegionDropdown
            id="state"
            countryValueType="short"
            country={formData.country}
            value={formData.state}
            onChange={(val) => setFormData({ ...formData, state: val })}
            required
          />
        </div>
        <div className="form-group">
          <label>Zip/Postal Code*</label>
          <input
            type="number"
            placeholder="Zip/Postal Code"
            value={formData.zipCode}
            onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Country*</label>
          <CountryDropdown
            id="country"
            value={formData.country}
            onChange={(val) => setFormData({ ...formData, country: val })}
            valueType="short"
            required
          />
        </div>
        <div className="form-group">
          <label>Billing Phone*</label>
          <input
            type="number"
            placeholder="Billing Phone#"
            value={formData.billingPhone}
            onChange={(e) => setFormData({ ...formData, billingPhone: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="terms-section">
        <input
          type="checkbox"
          id="termsCheckbox"
          checked={isCheckboxChecked}
          onChange={(e) => setIsCheckboxChecked(e.target.checked)}
          required
        />
        <label htmlFor="termsCheckbox">
          By checking this box you agree that you have read all details of your trip along with the instructions
          below and accepted our{' '}
          <Link className="link" style={{color:"#283C8B"}}>Terms and Conditions, Cancellation and Privacy policies</Link>. You also acknowledge
          that this will serve as your electronic signature and one-time authorization for the charges on your
          credit/debit card.
        </label>
      </div>
     
    </form>
   
   
  </div>
  
  );
};

export default PaymentDetails;