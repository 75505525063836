import React from 'react';
import './Choose.css';  // CSS File for styling

const img1 = require('../../Assets/Booking-bro.png')
const img2 = require('../../Assets/Flight Booking-cuate.png')
const img3 = require('../../Assets/Airport-bro.png')

const ChooseUs = () => {
  const reasons = [
    {
      imgSrc: img2, // Replace with actual path or use import for images
      title: 'One search, all the flights',
      description: 'We find you the best flight deals and travel hacks so that you can choose how to book.'
    },
    {
      imgSrc: img1, // Replace with actual path or use import for images
      title: 'Rise above all travel anxieties',
      description: 'With the planetfare.com Guarantee we have your back with whatever happens.'
    },
    {
      imgSrc: img3, // Replace with actual path or use import for images
      title: 'Trusted by millions',
      description: 'Join many travelers booking cheap flights with ease on Planet Fare.'
    }
  ];

  return (
    <div className="choose-container">
      <div className="choose-reasons">
        {reasons.map((reason, index) => (
          <div key={index} className="choose-item">
            <img src={reason.imgSrc} alt={reason.title} className="choose-img" />
            <h3 className="choose-title">{reason.title}</h3>
            <p className="choose-description">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseUs;
