import React from 'react';
import './Contact.css';
import Header from '../../HomeComponents/Header/Header';
import { FaPhoneFlip } from "react-icons/fa6";


const overlay = require('../../Assets/Images/Overlay.png');
const plane = require('../../Assets/Images/Rectangle 12.png')
const email = require('../../Assets/Images/carbon_email.png')
const phone = require('../../Assets/Images/ph_phone.png')

const Contact = () => {
  return (

    <>
      <Header />
      <div className="contact-us-main-section">

        <div className="contact-us-main-container">
          <div className="contact-us-img-container">
            <img src={overlay} alt="" />
            <div className="contact-us-text-overlay">
              Contact Us
            </div>
          </div>

          <div className="contact-us-form-container">
            <div className="contact-us-left">
              <div className="left-head-text">
                <div className='head-text'>Get In Touch</div>
              </div>

              <div className="left-second-head-text">
                <h3>Let’s Chat, Reach Out <br /> To Us</h3>
              </div>

              <div className="left-small-para">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem blanditiis, at quas explicabo quia rem, eius
                  asperiores temporibus ipsa iusto sed molestiae, dolores dolorem. Quod optio incidunt atque quo iste!</p>
              </div>

              <hr className='hr' />

              <div className="left-form-container">

                <form className='form' action="">
                  <div className="form-div-main-container">
                    <div className="input-div">
                      <label htmlFor="">First Name</label>
                      <input placeholder='First Name' type="text" />
                    </div>

                    <div className="input-div">
                      <label htmlFor="">Last Name</label>
                      <input placeholder='Last Name' type="text" />
                    </div>
                  </div>

                  <div className="second-div">
                    <label htmlFor="">Email Address</label>
                    <input placeholder='Email Address' type="email" name="" id="" />
                  </div>

                  <div className="third-div">
                    <label htmlFor="">Message</label>
                    <textarea placeholder='Leave Us Message' name="" id=""></textarea>
                  </div>

                  <button class="fancyy">
                    <span class="top-keyy"></span>
                    <span class="textt">Contact Us <FaPhoneFlip/></span>
                    <span class="bottomm-key-1"></span>
                    <span class="bottomm-key-2"></span>
                  </button>

                </form>
              </div>
            </div>

            <div className="contact-us-right">
              <div className="contact-us-right-image-container">
                <img src={plane} alt="" />
              </div>

              <div className="contact-us-contact-detail-section">
                <div className="section-one">
                  <div className="email-image-container">
                    <img src={email} alt="" />
                  </div>
                  <div className="contact-us-email">
                    <div className="bold">Email</div>
                    <div className="textt">support@planetfares.com</div>
                  </div>
                </div>

                <div className="section-one">
                  <div className="email-image-container">
                    <img src={phone} alt="" />
                  </div>
                  <div className="contact-us-email">
                    <div className="bold">Phone</div>
                    <div className="textt">+1 (844) 417-7506</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
