import React from 'react';
import './PriceDetails.css';
import { Link } from 'react-router-dom';

const PriceDetails = ({ flightData }) => {


  const totalPassenger = flightData.adults + flightData.childrens + flightData.infants;

  return (
    <div className="price-details-section">
      <div className="price-details-container">
        <p className="payment-time-warning">
          Please Make Your Payment Within The Next <strong>20 Minutes</strong> To Keep This Session Active.
        </p>
        <div className="price-details">
          <h2>Price Details (INR)</h2>
          <div className="price-item">
            <span>Travelers</span>
            <span>Subtotal</span>
          </div>
          <div className="price-item">
            <span>Adult</span>
            <span style={{fontWeight:'bold'}}>{totalPassenger}</span>
            <span>$ {flightData.totalFare}</span>
          </div>
        
          <hr />
          <div className="price-total">
            <span>Total Price (USD)</span>
            <span>$ {flightData.totalFare}</span>
          </div>
          <p className="price-note">
            As Low As $72/Mo With Affirm <a href="#">Learn More</a>
          </p>
          <p className="disclaimer">
            All Fares Are Quoted In United States Dollars. Some Airlines May Charge Baggage Fees. Your Credit/Debit Card May Be Billed In Multiple Charges Totaling The Final Total Price. Promo Code Is Off Our Service Fees.
          </p>
          <div className="promo-code">
            <label htmlFor="promo-code-input">Enter Promo Code Or Gift Card Number</label>
            <select id="promo-code-input">
              <option value="">Select</option>
              <option value="promo1">Promo Code 1</option>
              <option value="promo2">Promo Code 2</option>
            </select>
          </div>
          <button className="continue-button">$ {flightData.totalFare}</button>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
