import React, { useEffect, useState } from 'react';
import { ImUser } from "react-icons/im";
import './AdultFrom.css';

const AdultForm = ({ onFormSubmit, totalPassenger }) => {
  const [passengers, setPassengers] = useState([]);

  useEffect(() => {
    setPassengers(Array.from({ length: totalPassenger }, (_, index) => index + 1).map((passengerId) => ({
      id: passengerId,
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      loyaltyProgram: 'None Requested',
      meal: '',
      specialAssistance: '',
      mileagePlusNumber: '',
    })));
  }, [totalPassenger]);

  const handleChange = (e, passengerId) => {
    const { name, value } = e.target;
    setPassengers(passengers.map((passenger) =>
      passenger.id === passengerId ? { ...passenger, [name]: value } : passenger
    ));
  };

  const handleSubmitAdult = (e, passengerId) => {
    e.preventDefault();
    const formData = passengers.find((passenger) => passenger.id === passengerId);
    onFormSubmit(formData);
  };

  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);

  const isFormValid = (passenger) => {
    const requiredFields = ['firstName', 'lastName', 'gender', 'birthDay', 'birthMonth', 'birthYear', 'meal', 'specialAssistance'];
    return requiredFields.every(field => !!passenger[field]); 
  };

  const canSubmitForm = passengers.every(isFormValid); 

  useEffect(() => {
    if (canSubmitForm) {
      onFormSubmit(passengers); 
    }
  }, [passengers]);

  return (
    <div className="form-section">
   
    <p>(Please Make Sure Names Match Government-Issued ID)</p>
    {passengers.map((passenger) => (
      
      <form key={passenger.id} className="flying-form" onSubmit={(e) => handleSubmitAdult(e, passenger.id)}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor={`gender-${passenger.id}`}>Choose One*</label>
            <select id={`gender-${passenger.id}`} name="gender" value={passenger.gender} onChange={(e) => handleChange(e, passenger.id)} required>
              <option value="">Choose one</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`firstName-${passenger.id}`}>First Name*</label>
            <input type="text" id={`firstName-${passenger.id}`} name="firstName" placeholder="First Name" value={passenger.firstName} onChange={(e) => handleChange(e, passenger.id)} required />
          </div>
          <div className="form-group">
            <label htmlFor={`middleName-${passenger.id}`}>Middle Name</label>
            <input type="text" id={`middleName-${passenger.id}`} name="middleName" placeholder="Middle Name" value={passenger.middleName} onChange={(e) => handleChange(e, passenger.id)} />
          </div>
          <div className="form-group">
            <label htmlFor={`lastName-${passenger.id}`}>Last Name*</label>
            <input type="text" id={`lastName-${passenger.id}`} name="lastName" placeholder="Last Name" value={passenger.lastName} onChange={(e) => handleChange(e, passenger.id)} required />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor={`dob-${passenger.id}`}>Date of Birth*</label>
            <select name="birthMonth" value={passenger.birthMonth} onChange={(e) => handleChange(e, passenger.id)} required>
              <option value="">Month</option>
              {months.map((month, index) => (
                <option key={index} value={index + 1}>{month}</option>
              ))}
            </select>
            <select name="birthDay" value={passenger.birthDay} onChange={(e) => handleChange(e, passenger.id)} required>
              <option value="">Day</option>
              {Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
                <option key={day} value={day}>{day}</option>
              ))}
            </select>
            <select name="birthYear" value={passenger.birthYear} onChange={(e) => handleChange(e, passenger.id)} required>
              <option value="">Year</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`loyaltyProgram-${passenger.id}`}>Select Loyalty Program</label>
            <select id={`loyaltyProgram-${passenger.id}`} name="loyaltyProgram" value={passenger.loyaltyProgram} onChange={(e) => handleChange(e, passenger.id)}>
              <option value="None Requested">None Requested</option>
              <option value="American AAdvantage">American AAdvantage</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`mileagePlusNumber-${passenger.id}`}>Mileage Plus Number</label>
            <input type="text" id={`mileagePlusNumber-${passenger.id}`} name="mileagePlusNumber" placeholder="Mileage Plus Number" value={passenger.mileagePlusNumber} onChange={(e) => handleChange(e, passenger.id)} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor={`meal-${passenger.id}`}>Select A Meal</label>
            <select id={`meal-${passenger.id}`} name="meal" value={passenger.meal} onChange={(e) => handleChange(e, passenger.id)}>
              <option value="">Select a Meal</option>
              <option value="Vegetarian">Vegetarian</option>
              <option value="Non-Vegetarian">Non-Vegetarian</option>
              <option value="Vegan">Vegan</option>
              <option value="Gluten-Free">Gluten-Free</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`specialAssistance-${passenger.id}`}>Special Assistance</label>
            <select id={`specialAssistance-${passenger.id}`} name="specialAssistance" value={passenger.specialAssistance} onChange={(e) => handleChange(e, passenger.id)}>
              <option value="">Select Assistance</option>
              <option value="None Requested">None Requested</option>
              <option value="Wheelchair">Wheelchair</option>
              <option value="Blind Passenger">Blind Passenger</option>
              <option value="Deaf Passenger">Deaf Passenger</option>
              <option value="Stretcher">Stretcher</option>
              <option value="Medical Case">Medical Case</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`knownTravelerId-${passenger.id}`}>Known Traveler ID</label>
            <input type="text" id={`knownTravelerId-${passenger.id}`} name="knownTravelerId" placeholder="Known Traveler ID" value={passenger.knownTravelerId} onChange={(e) => handleChange(e, passenger.id)} />
          </div>
        </div>
      </form>
    ))}
  </div>
  );
};

export default AdultForm;
