// TopRoutes.js
import React from 'react';
import './TopFlight.css';
import Loader from '../../Pages/Loader';

const topRoutes = [
  { origin: "GLA", originCode: "ABQ",destination: "ABQ" ,destinationCode: "STN"},
  { origin: "Albuquerque", originCode: "ABQ", destination: "Kahului", destinationCode: "BWI" },
  { origin: "Atlanta", originCode: "ATL",destination: "Baltimore" ,destinationCode: "BWI"},
  { origin: "Atlanta", originCode: "ATL",destination: "Boston" ,destinationCode: "BOS" },
  { origin: "Atlanta", originCode: "ATL",destination: "Bradley" ,destinationCode: "BDL"},
  { origin: "Atlanta", originCode: "ATL",destination: "Chicago",destinationCode: "ORD" },
  { origin: "Atlanta", originCode: "ATL",destination: "Columbus" ,destinationCode: "CMH"},
  { origin: "Atlanta", originCode: "ATL",destination: "Dallas" ,destinationCode: "DFW"},
  { origin: "Atlanta", originCode: "ATL",destination: "Denver" ,destinationCode: "DEN"},
  { origin: "Atlanta", originCode: "ATL",destination: "Fort Myers" ,destinationCode: "RSW"},
  { origin: "Atlanta", originCode: "ATL",destination: "Honolulu" ,destinationCode: "HNL"},
  { origin: "Atlanta", originCode: "ATL",destination: "Houston" ,destinationCode: "IAH"},
  { origin: "Atlanta", originCode: "ATL",destination: "Las Vegas" ,destinationCode: "LAS"},
  { origin: "Atlanta", originCode: "ATL",destination: "Los Angeles" ,destinationCode: "LAX"},
  { origin: "Atlanta", originCode: "ATL",destination: "New York" ,destinationCode: "NYC"},
  { origin: "Atlanta", originCode: "ATL",destination: "Philadelphia" ,destinationCode: "PHL"},
  { origin: "Atlanta", originCode: "ATL",destination: "San Francisco" ,destinationCode: "SAF"},
  { origin: "Atlanta", originCode: "ATL",destination: "Seattle" ,destinationCode: "SEA"},
  { origin: "Atlanta", originCode: "ATL",destination: "Tampa" ,destinationCode: "TPA"},
  { origin: "Chicago", originCode: "ORD", destination: "Atlanta" ,destinationCode: "ATL"},
  { origin: "Honolulu", originCode: "HNL",destination: "Atlanta" ,destinationCode: "ATL"},
  { origin: "Los Angeles", originCode: "LAX",destination: "Atlanta" ,destinationCode: "ATL"},
];

const TopRoutes = ({ handleSearch, loading }) => {
  return (
    <>{loading ? (<div
      style={{
        position: "fixed",
        top: 0,
        bottom:0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <Loader />
    </div>) :(
    <div className="routes-container">
      
      <div className="routes-header">Top Flight <span style={{color:"#00a58e"}}>Routes</span></div>
      <div className="routes-list">
        {topRoutes.map((route, index) => (
          <span
            key={index}
            className="route-item"
            onClick={() => handleSearch(route.originCode, route.destinationCode)}
          >
            {route.origin} To {route.destination}
          </span>
        ))}
      </div>
      
    </div>
    )}
    </>
    
  );
};

export default TopRoutes;
