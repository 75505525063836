import React, { useState } from 'react';
import './PopularDeal.css';
import TwoFlight from "../../Assets/twoflight.png"
import F from "../../Assets/f.png";
import S from "../../Assets/s.png";
import Spirit from "../../Assets/spirit.png";
import Ar from "../../Assets/ar.png";
import J from "../../Assets/j.png";
import W from "../../Assets/w.png";
import H from "../../Assets/h.png";
import G from "../../Assets/g.png";
import Originn from "../../Assets/origin.png"
import Loader from '../../Pages/Loader';
const PopularDeals = ({ handleSearch, loading }) => {

  // const [loading, setLoading] = useState(false);
  const deals = [
    {
      origin: 'LAS',
      destination: 'ONT',
      price: '$42.34',
      departureDate: 'Jun 03, 2024',
      arrivalDate: 'Jun 03, 2024',
      isDomestic: true,
      image: F,
    },
    {
      origin: 'DTW',
      destination: 'MSP',
      price: '$113.09',
      departureDate: 'May 30, 2024',
      arrivalDate: 'May 30, 2024',
      isDomestic: true,
      image: S,
    },
    {
      origin: 'MCO',
      destination: 'BOS',
      price: '$212.2',
      departureDate: 'Sep 27, 2024',
      arrivalDate: 'Sep 27, 2024',
      isDomestic: true,
      image: J,
    },
    {
      origin: 'CLE',
      destination: 'LGA',
      price: '$228.2',
      departureDate: 'Mar 31, 2024',
      arrivalDate: 'Mar 31, 2024',
      isDomestic: false,
      image: W,
    },
    {
      origin: 'LAX',
      destination: 'BNA',
      price: '$321.0',
      departureDate: 'Jun 13, 2024',
      arrivalDate: 'Jun 13, 2024',
      isDomestic: true,
      image: Spirit,
    },
    {
      origin: 'MIA',
      destination: 'IEV',
      price: '$236.19',
      departureDate: 'Jun 19, 2024',
      arrivalDate: 'Jun 19, 2024',
      isDomestic: false,
      image: G,
    },
    {
      origin: 'OGD',
      destination: 'SFO',
      price: '$176.21',
      departureDate: 'Apr 17, 2024',
      arrivalDate: 'Apr 17, 2024',
      isDomestic: true,
      image: H,
    },
    {
      origin: 'BUR',
      destination: 'SAN',
      price: '$221.05',
      departureDate: 'Apr 22, 2024',
      arrivalDate: 'Apr 22, 2024',
      isDomestic: true,
      image: Ar,
    },
    
  ];


  return (
  <>
  {loading ? (<div
      style={{
        position: "fixed",
        top: 0,
        bottom:0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <Loader />
    </div>) :(
     <div className="popular-deals-container">
     <h2>Popular <span style={{color:"#00a58e"}}>Deals</span></h2>
     <div className="deals-grid">
       {deals.map((deal, index) => (
         <div className="deal-card" key={index}>
           <div className="deal-info">
             <div className="deal-cities">
             <img src={deal.image} alt="" />
             </div>
             <div className="deal-cities">
               <h3 className="origin">{deal.origin}</h3>
               <h4 className="destination">{deal.arrivalDate}</h4>
             </div>
             <img src={TwoFlight} alt="" className="two-flight-animation"  />
             <div className="deal-cities">
               <h3 className="origin">{deal.destination}</h3>
               <h4 className="destination">{deal.departureDate}</h4>
             </div>

             <div className="deal-cities">
               <h3 className="origin">{deal.price}</h3>
               <button className='dealsB' onClick={() => handleSearch(deal.origin, deal.destination)}>search now</button>
             </div>
             {/* <span className="departure-date">{deal.departureDate}</span> */}
           </div>
           
         </div>
       ))}
     </div>
   </div>
  )}

  </>
  );
};

export default PopularDeals;