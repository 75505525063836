import React from 'react';
import './CallBack.css';
import { IoCallSharp } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';

const CallBack = () => {
    
    const location = useLocation();
    const phoneNumber = location.state.phoneNumber;


  return (
    <div className="callback-container">
      <div className="callback-card">
        <div className="callback-icon">
          <svg
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 12l2 2 4-4"
              stroke="#283C8B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10z"
              stroke="#283C8B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h2 className="callback-title">We'll call you back!</h2>
        <p className="callback-message">
          Request received so an agent will contact you at
          <br />
          <strong>{phoneNumber}</strong> in 3 to 5 minutes
        </p>
        <button className="callback-button">
         <IoCallSharp/>
          Call Now: (833) 436-0717
        </button>
        <Link to={'/'}  className="callback-new-request">
          Home
        </Link>
      </div>
    </div>
  );
};

export default CallBack;
